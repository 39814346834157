@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Figtree;
  margin: auto;
}

.admin__loader {
  width: 60px;
  height: 60px;
  border: 8px solid #091579;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.access__btn__loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: green #0000 red #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.access__btn__loader:before,
.access__btn__loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: red;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.access__btn__loader:after {
  border-color: green #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.btns__loader {
  width: 25px;
  height: 25px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #edecec;
}

::-webkit-scrollbar-thumb {
  background: #abaec6;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #091579;
}
